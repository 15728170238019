<template>
    <div class="container">
        <div class="header">
            <van-icon name="cross" size="16" color="#000" class="close" @click="$router.push({path: '/self/service/bill/banklist/bank'})" />
        </div>
        <div class="tip">
            <img src="/images/zhaoshang-bank.png" alt="">
            <div class="title">刷脸认证</div>
            <div class="text">当前功能只有微信支付实名本人（{{ trueName }}）才能使用，请确保本人操作。</div>
        </div>
        <div class="btn">
            <div @click="isShowPop = true">验证</div>
        </div>
        <van-popup v-model="isShowPop" :close-on-click-overlay="false" position="bottom">
            <div class="title">使用刷脸服务需同意以下协议</div>
            <div class="text">《微信支付刷脸核身服务协议》</div>
            <div class="btns">
                <div class="btn1" @click="$router.go(-1)">不同意</div>
                <div class="btn2" @click="toFace">同意</div>
            </div>
        </van-popup>
    </div>


</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            isShowPop: false
        }
    },
    computed: {
        ...mapState({
            trueName: state => {
                const { username } = state.userInfo
                return `*${username[username.length-1]}`
            }
        })
    },
    methods: {
        toFace() {
            this.$router.push({ path: '/self/service/bill/banklist/bank/auth/face'})
        }
    }
}

</script>

<style lang="less" scoped>
.container {
    background: #fff;
}
.header {
    .van-icon {
        margin-left: 10px;
        margin-top: 10px;
    }
}
.tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    img {
        margin-bottom: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .text {
        text-align: center;
    }
}
.btn {
    position: fixed;
    bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    div {
        width: 180px;
        height: 40px;
        line-height: 40px;
        background: #2BAC69;
        border-radius: 6px;
        color: #fff;
        font-weight: 600;
        text-align: center;

    }
}
.van-popup {
    .title {
        font-size: 14px;
        margin-left: 14px;
        margin-top: 20px;
        font-weight: 400;
    }
    .text {
        margin-left: 16px;
        color: rgb(66, 102, 180);
        margin-top: 22px;
        margin-bottom: 30px;
    }
    .btns {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        div {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 30px;
            padding-right: 30px;
            font-size: 16px;
            border-radius: 6px;
        }
        .btn1 {
            background:#f3f2f2;
            margin-right: 20px;
        }
        .btn2 {
            background: #2BAC69;
            color: #fff;
        }
    }
} 
</style>